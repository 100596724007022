import { ref } from "@vue/reactivity";
import { computed, watch } from "@vue/runtime-core";

import engine from "../core/index";
import mainEngine from "@/core/index";
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import moment from 'moment';
import { onMounted } from "vue";

export default function homePageData() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore()
    const clickOnDeleteBtn = ref(false)
    const newMaterielData = ref({})
    const snackbarData = ref({})
    const selectedEtadl = ref([])
        // const newPathFromQuery = ref(undefined)
    const daysToHide = ref([]);
    const technicienListe = ref([]);
    const textColor = ref('white');
    const secondColor = ref('#455A64');
    const etadlOptions = ref({})
    const defaultForm = ref(undefined);
    const defaultFormValues = ref([]);
    const tableHeaders = ref([]);
    const sortDateFilter = ref(1);
    const selectedSubFamilly = ref(undefined);
    const selectedTechnicien = ref([]);
    const selectedAgency = ref(undefined);
    const isLoading = ref(false);
    const isqCodeReaderOpen = ref(false);
    const isAdmin = ref(false);
    const dialog = ref(false);
    const modalUser = ref(false);
    const search = ref("");
    const selectedItem = ref([0, 2, 3]);
    const selectedMaterial = ref({});
    const dialogData = ref({});
    const materials_list = ref(undefined);
    const agency_list = ref(undefined);
    const parcListe = ref(undefined);
    const removeEtadlData = ref({ value: false, data: {} })

    onMounted(() => {
        let token = localStorage.getItem("userToken");
        engine.get_default_form(function(data) {
            let localForm = undefined;
            if (data.code == 0) {
                localForm = data.data.data.map((val) => ({
                    key: val.fieldData.name,
                    value: val.fieldData.default,
                    selection: val.fieldData.selection
                }));
                localForm = localForm.map((val) => ({
                    CH_DESIGNATION: val.key,
                    CLD_QTE_DEPART: val.value,
                    CLD_QTE_RETOUR: val.value,
                    CLD_SELECTION: val.selection,
                }));
                defaultForm.value = localForm;
            }
        });


        engine.get_default_form_values(function(data) {
            if (data.code == 0) {
                defaultFormValues.value = data.data.data.map(
                    (val) => val.fieldData.value
                );
            }
        });
        engine.get_headers_table(function(data) {
            if (data.code == 0) {
                tableHeaders.value = data.data.data.map((val) => ({ key: val.fieldData.header, label: val.fieldData.label, idx: val.fieldData.idx, type: val.fieldData.type }));
                tableHeaders.value.sort((a, b) => a.idx - b.idx)
            }
        });

        const verifOptions = function(options, key, isBool) {
            let findedKey = options.find((val) => val.Key == key)
            if (findedKey) {
                let val = findedKey.Value
                if (isBool) {
                    if (parseInt(val) == 0)
                        return true
                    else
                        return false
                } else {
                    let monNombre = parseInt(val);
                    if (!isNaN(monNombre))
                        return monNombre
                    else
                        return val
                }
            } else return false
        }

        engine.get_options(function(data) {
            if (data.code == 0) {
                let options = data.data.data.map((val) => ({ Key: val.fieldData.Key, Value: val.fieldData.Value }))
                    // etadlOptions.value['nbrMaxLoadedPhoto'] = parseInt(options.find((val) => val.Key == 'nbrMaxPhoto').Value)
                    // nbrMinLoadedPhoto.value = parseInt(options.find((val) => val.Key == 'nbrMinPhoto').Value)
                    // etadlOptions.value['showAgencySelector'] = parseInt(options.find((val) => val.Key == 'showCategory').Value) == 0
                    // etadlOptions.value['drawInObservation'] = parseInt(options.find((val) => val.Key == 'drawInObservation').Value) == 0
                    // etadlOptions.value['multipleSelection'] = parseInt(options.find((val) => val.Key == 'multipleSelection').Value) == 0
                    // etadlOptions.value['showErrorInContrat'] = parseInt(options.find((val) => val.Key == 'showErrorInContrat').Value) == 0
                    // etadlOptions.value['showSignByMail'] = parseInt(options.find((val) => val.Key == 'showSignByMail').Value) == 0
                    // etadlOptions.value['sendAlertNotSigned'] = parseInt(options.find((val) => val.Key == 'sendAlertNotSigned').Value) == 0

                etadlOptions.value['nbrMaxLoadedPhoto'] = verifOptions(options, 'nbrMaxPhoto', false)
                etadlOptions.value['nbrMinLoadedPhoto'] = verifOptions(options, 'nbrMinPhoto', false)
                etadlOptions.value['showAgencySelector'] = verifOptions(options, 'showCategory', true)
                etadlOptions.value['drawInObservation'] = verifOptions(options, 'drawInObservation', true)
                etadlOptions.value['multipleSelection'] = verifOptions(options, 'multipleSelection', true)
                etadlOptions.value['showErrorInContrat'] = verifOptions(options, 'showErrorInContrat', true)
                etadlOptions.value['setSignataire'] = verifOptions(options, 'setSignataire', true)
                etadlOptions.value['showSignByMail'] = verifOptions(options, 'showSignByMail')
                etadlOptions.value['sendAlertNotSigned'] = verifOptions(options, 'sendAlertNotSigned')
                etadlOptions.value['savedSignature'] = verifOptions(options, 'savedSignature', false)
            }
        })
        mainEngine.get_user_by_token(token, function(data) {
            if (data.code == 0) {
                if (data.data.data.data[0].fieldData.admin == 1) isAdmin.value = true;
            } else {
                logout(true);
            }
        });
        engine.get_agency_soft(function(data) {
            if (data.code === 0) {
                agency_list.value = formatFromServerToTable(data.data.data);
                agency_list.value = agency_list.value.map((val) => val.data);
                selectedAgency.value = agency_list.value.find(
                    (val) => val.ID_Agence == get_user_information.value.user_id_agence
                );
                setMaterialList();
            }
        });

        getParcListe()
    })

    // const getOption = computed(function() {
    //     return etadlOptions.value
    // })

    const getParcListe = function() {
        engine.get_parc(function(data) {
            console.log("get_parc", data);

            if (data.code == 0) {
                parcListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }


    const getTableHeight = computed(function() {
        let height = 200
        let pourcent = window.innerHeight / 100
        if (window.innerWidth < 955) {
            height = pourcent * 56
            if (etadlOptions.value['showAgencySelector']) {
                height = pourcent * 50
            }
        } else {
            height = pourcent * 63
            if (etadlOptions.value['showAgencySelector']) {
                height = pourcent * 62
            }
        }
        return height
    })

    const getNbrPhotoToLoad = function(data, addedPhoto) {
        let keysTab = ['CLD_PHOTO_1', 'CLD_PHOTO_2']
        if (data["CLD_CONTROLE_DEPART"] == '0' && data["CLD_CONTROLE_RETOUR"] == '0') {
            if (addedPhoto[keysTab[0]]) {
                return addedPhoto[keysTab[0]]
            }
        }
        if (data["CLD_CONTROLE_DEPART"] == '1' && data["CLD_CONTROLE_RETOUR"] == '0') {
            if (addedPhoto[keysTab[1]]) {
                return addedPhoto[keysTab[1]]
            }
        }
        if (data["CLD_CONTROLE_DEPART"] == '1' && data["CLD_CONTROLE_RETOUR"] == '1') {
            let nbr = 0
            if (addedPhoto[keysTab[0]]) {
                nbr += addedPhoto[keysTab[0]]
            }
            if (addedPhoto[keysTab[1]]) {
                nbr += addedPhoto[keysTab[1]]
            }
            return nbr
        }
        return undefined
    }

    // const maxPaginationNumber = computed(function() {
    //     return Math.ceil(this.get_tab_items.length / this.nbrPagination);
    // })

    const getSubFamilly = computed(function() {
        if (get_tab_items.value == undefined)
            return []
        let subFamilly = get_tab_items.value.map((val) => val.CLD_SOUS_FAMILLE);
        subFamilly = [...new Set(subFamilly)];
        return subFamilly;
    })

    const getTechnicienListe = function() {
        if (get_tab_items.value == undefined) {
            return []
        }
        let technicien = get_tab_items.value.filter((val) => val.CLD_TECHNICIEN_DEPART != undefined).map((val) => val.CLD_TECHNICIEN_DEPART.toUpperCase());
        if (technicien && technicien.length > 0 && technicien[0]) {
            technicien = [...new Set(technicien)];
            selectedTechnicien.value = [get_user_information.value.user_name.toUpperCase()]
            return technicien;
        } else {
            return []
        }
    }

    const get_user_information = computed(function() {
        return store.state.user;
    })

    const dataMenu = computed(function() {
        return [{
                title: "Départ",
                option: {
                    btn: ["Départ"],
                    key: "depart",
                    title: "Départ",
                    disabled: { entrer: true, sortie: false },
                },

                class: "sortie",
            },
            {
                title: 'Livrer',
                option: {
                    btn: ['clock-end'],
                    key: "attente",
                    title: 'Livrer',
                    disabled: { entrer: true, sortie: true },
                },
                class: "attente",
            },
            {
                title: "Reprise",
                option: {
                    btn: ["Retour"],
                    key: "return",
                    title: "Reprise",
                    disabled: { entrer: false, sortie: true },
                },
                class: "entrer",
            },
            {
                title: "Terminé",
                option: {
                    btn: ["Terminé"],
                    key: "returned",
                    title: "Terminé",
                    disabled: { entrer: true, sortie: true },
                },
                class: "termine",
            },
            {
                title: "En attente",
                option: {
                    btn: ["Retour"],
                    key: "waiting",
                    title: "En attente",
                    disabled: { entrer: true, sortie: true },
                },
                class: "waiting",
            },
        ];
    })

    const itemsMenu = computed(function() {
        return [{
                text: "Départ",
                icon: "mdi-arrow-right-bold",
                class: "sortie",
                idx: 0,
            },
            {
                text: 'Livrer',
                icon: "mdi-clock-end",
                class: "attente",
                idx: 1,
            },
            {
                text: "Retour",
                icon: "mdi-arrow-left-bold",
                class: "entrer",
                idx: 2,
            },
            {
                text: "Terminé",
                icon: "mdi-lock",
                class: "termine",
                idx: 3,
            },
            {
                text: "En attente",
                icon: "mdi-lock",
                class: "waiting",
                idx: 4,
            },
        ];
    })

    const get_client_state = computed(function() {
        let state = undefined;
        let OldState = undefined;
        if (selectedMaterial.value) {
            let lien = selectedMaterial.value.data["CLD_LIEN"];
            let dataList = materials_list.value.filter(
                (val) => val.data["CLD_LIEN"] === lien
            );
            if (dataList)
                for (let i = 0; i < dataList.length; i++) {
                    if (
                        dataList[i].data["CLD_CONTROLE_DEPART"] !==
                        dataList[i].data["CLD_CONTROLE_RETOUR"]
                    )
                        return "X";
                    if (
                        dataList[i].data["CLD_CONTROLE_DEPART"] === 1 &&
                        dataList[i].data["CLD_CONTROLE_RETOUR"] === 1
                    )
                        state = "2";
                    if (
                        dataList[i].data["CLD_CONTROLE_DEPART"] === 0 &&
                        dataList[i].data["CLD_CONTROLE_RETOUR"] === 0
                    )
                        state = "0";
                    if (OldState && state && OldState !== state) return "X";

                    OldState = state;
                    state = undefined;
                }
        }
        return OldState;
    })

    const get_tab_items = computed(function() {
        let tab_items = [];
        let data = JSON.parse(JSON.stringify(get_selected_material_data.value));

        for (let i = 0; i < data.length; i++) {
            let addInTab = true;
            let item = data[i];
            let id =
                item["CLD_NUM_CONTRAT"] +
                item["CLD_SOUS_FAMILLE"] +
                item["CLD_MATRICULE"] +
                item["CLD_DESCRIPTION"] +
                item["CLD_CLIENT"] +
                item["CLD_DATE_DEPART"] +
                item["CLD_CLIENT_RAISON_SOCIALE"] +
                item["CLD_NOM_CLIENT"] +
                item["CLD_DATE_RETOUR"];
            if (
                selectedSubFamilly.value != undefined &&
                item["CLD_SOUS_FAMILLE"] != selectedSubFamilly.value
            ) {
                addInTab = false;
            }
            if (search.value != undefined && search.value.length > 0 && !id.toUpperCase().includes(search.value.toUpperCase())) {
                addInTab = false;
            }
            if (addInTab) {
                tab_items.push(item);
            }
        }
        if (sortDateFilter.value == 1 || sortDateFilter.value == 2) {
            tab_items = tab_items.sort(function(a, b) {
                if (sortDateFilter.value == 1)
                    return new Date(b.CLD_DATE_DEPART) - new Date(a.CLD_DATE_DEPART);
                if (sortDateFilter.value == 2)
                    return new Date(a.CLD_DATE_DEPART) - new Date(b.CLD_DATE_DEPART);
            });
        }
        if (sortDateFilter.value == 3 || sortDateFilter.value == 4) {
            tab_items = tab_items.sort(function(a, b) {
                if (sortDateFilter.value == 3)
                    return new Date(b.CLD_DATE_RETOUR) - new Date(a.CLD_DATE_RETOUR);
                if (sortDateFilter.value == 4)
                    return new Date(a.CLD_DATE_RETOUR) - new Date(b.CLD_DATE_RETOUR);
            });
        }
        tab_items.sort((a, b) => b.CLD_PRIORITE_LIVRAISON - a.CLD_PRIORITE_LIVRAISON)

        return tab_items;
    })

    const get_tab_header = computed(function() {
        let tab_header = [];
        let list = tableHeaders.value;
        for (let i = 0; i < list.length; i++) {
            tab_header.push({
                text: list[i].label,
                value: list[i].key,
                type: list[i].type,
                sortable: true
            });
        }
        // if (isAdmin.value == true)
        tab_header.push({
            text: "",
            value: 'delete',
            sortable: false
        })
        return tab_header;
    })

    const get_selected_material_data = computed(function() {
        let finalData = [];
        for (let i = 0; i < selectedItem.value.length; i++) {
            let item = selectedItem.value[i]
            let tmpData = []
            switch (dataMenu.value[item].option.key) {
                case "depart":
                    tmpData = get_depart_material.value
                    break;
                case "return":
                    tmpData = get_return_material.value
                    break;
                    // case "waiting":
                    //     tmpData = get_waiting_material.value
                    //     break;
                case "returned":
                    tmpData = get_returned_material.value
                    break;
            }
            finalData = finalData.concat(tmpData)
        }
        return finalData;
    })

    const get_depart_material = computed(function() {
        let depart_material_list = [];
        if (materials_list.value) {
            for (let i = 0; i < materials_list.value.length; i++) {
                if (
                    materials_list.value[i].data["CLD_CONTROLE_DEPART"] == 0 &&
                    materials_list.value[i].data["CLD_CONTROLE_RETOUR"] == 0
                ) {
                    if (etadlOptions.value['showAgencySelector']) {
                        if (materials_list.value[i].data.CLD_ID_AGENCE ==
                            selectedAgency.value.ID_Agence) depart_material_list.push(
                            get_materials_data_to_show(materials_list.value[i])
                        );
                    } else {
                        depart_material_list.push(
                            get_materials_data_to_show(materials_list.value[i])
                        );
                    }
                }
            }
        }
        return depart_material_list;
    })

    const get_waiting_material = computed(function() {
        let depart_material_list = [];
        if (materials_list.value) {
            for (let i = 0; i < materials_list.value.length; i++) {
                if (
                    materials_list.value[i].data["CLD_WAITING"] == 1
                ) {
                    if (etadlOptions.value['showAgencySelector']) {
                        if (materials_list.value[i].data.CLD_ID_AGENCE ==
                            selectedAgency.value.ID_Agence) depart_material_list.push(
                            get_materials_data_to_show(materials_list.value[i])
                        );
                    } else {
                        depart_material_list.push(
                            get_materials_data_to_show(materials_list.value[i])
                        );
                    }
                }
            }
        }
        return depart_material_list;
    })

    const get_return_material = computed(function() {
        let return_material_list = [];
        if (materials_list.value)
            for (let i = 0; i < materials_list.value.length; i++) {
                if (
                    materials_list.value[i].data["CLD_CONTROLE_DEPART"] == 1 &&
                    materials_list.value[i].data["CLD_CONTROLE_RETOUR"] == 0
                ) {
                    if (etadlOptions.value['showAgencySelector']) {
                        if (materials_list.value[i].data.CLD_ID_AGENCE ==
                            selectedAgency.value.ID_Agence)
                            return_material_list.push(
                                get_materials_data_to_show(materials_list.value[i])
                            )
                    } else {
                        return_material_list.push(
                            get_materials_data_to_show(materials_list.value[i])
                        );
                    }
                }
            }
        return return_material_list;
    })

    const get_returned_material = computed(function() {
        let returned_material_list = [];
        if (materials_list.value)
            for (let i = 0; i < materials_list.value.length; i++) {
                if (
                    materials_list.value[i].data["CLD_CONTROLE_DEPART"] == 1 &&
                    materials_list.value[i].data["CLD_CONTROLE_RETOUR"] == 1
                ) {
                    if (etadlOptions.value['showAgencySelector']) {
                        if (
                            materials_list.value[i].data.CLD_ID_AGENCE ==
                            selectedAgency.value.ID_Agence
                        )
                            returned_material_list.push(
                                get_materials_data_to_show(materials_list.value[i])
                            )
                    } else {
                        returned_material_list.push(
                            get_materials_data_to_show(materials_list.value[i])
                        );
                    }
                }
            }
        return returned_material_list;
    })

    const getItemType = function(dep, ret, waiting) {
        let idx = 0

        if (waiting == '1')
            idx = 4
        else if (dep == 0 && ret == 0)
            idx = 0
        else if (dep == 'X' && ret == 0)
            idx = 1
        else if (dep == 1 && ret == 0)
            idx = 2
        else if (dep == '1' && ret == 1)
            idx = 3
        return dataMenu.value[idx]
    }

    const formatDate = function(date, format) {
        return moment(date).locale("fr").format(format);
    }

    const setPhoto = function(formData, key, data, i) {
        let dropBoxPath = "LOCATION/" +
            formData["CLD_NUM_CONTRAT"] +
            "/" +
            formData["CLD_MATRICULE"].replaceAll("/", "@") +
            "/" +
            "PHOTOS" +
            "/" +
            key +
            "_" +
            i +
            ".png";

        if (i < data.length) {
            engine.file_upload(data[i], dropBoxPath, function() {
                setPhoto(formData, key, data, i + 1);
            });
        }
    }

    const uploadPhoto = function(key, data, formData) {
        formData = formData.data;
        data = Object.values(JSON.parse(data));
        let keyTrade = { CLD_PHOTO_1: "DEPART", CLD_PHOTO_2: "RETOUR" };
        if (data.length > 0) {
            deleteAllPhotos(formData, keyTrade[key], data)
                // setPhoto(formData, keyTrade[key], data, 0);
        }
    }

    const deleteAllPhotos = function(formData, key, dataPhotos) {
        let nbrDeletedPhoto = 0
        let dropBoxPath = "LOCATION/" +
            formData["CLD_NUM_CONTRAT"] +
            "/" +
            formData["CLD_MATRICULE"].replaceAll("/", "@") +
            "/" +
            "PHOTOS" +
            "/" +
            key +
            "_"
        for (let i = 0; i < etadlOptions.value['nbrMaxLoadedPhoto']; i++) {
            engine.photo_delete(dropBoxPath + i +
                ".png",
                function() {
                    nbrDeletedPhoto += 1
                    if (nbrDeletedPhoto == etadlOptions.value['nbrMaxLoadedPhoto']) {
                        setPhoto(formData, key, dataPhotos, 0);
                    }
                });
        }
    }

    const updateTable = function() {
        setMaterialList();
    }

    const user = function() {
        modalUser.value = true;
    }

    const logout = function(isRedirect) {
        if (isRedirect) {
            if (this.$route && this.$route.query) {
                let query = this.$route.query;
                query["type"] = "redirect";
                router.push({ path: "/etat-des-lieux-materiel", query });
            }
        } else
            router.push({
                path: "/etat-des-lieux-materiel",
                query: { type: "disconnection" },
            });

        // localStorage.clear();
        localStorage.setItem("userToken", undefined);
    }

    const setMaterialList = function() {
        isLoading.value = true
        engine.get_materials(function(data) {
            if (data.code === 0) {
                materials_list.value =
                    formatFromServerToTable(data.data.data)
                    .reverse();

                if (
                    route.query &&
                    route.query.contrat &&
                    route.query.matricule
                ) {
                    let findedContrat = undefined;
                    try {
                        findedContrat = JSON.parse(
                            JSON.stringify(
                                materials_list.value.find(
                                    (val) =>
                                    val.data.CLD_NUM_CONTRAT === route.query.contrat &&
                                    val.data.CLD_MATRICULE === route.query.matricule
                                )
                            )
                        );
                    } catch (e) {
                        console.log(e);
                    }
                    router.push(route.path);
                    if (typeof findedContrat === "object") {
                        let state = undefined;
                        if (
                            findedContrat.data["CLD_CONTROLE_DEPART"] !==
                            findedContrat.data["CLD_CONTROLE_RETOUR"]
                        )
                            state = 1;
                        if (
                            findedContrat.data["CLD_CONTROLE_DEPART"] === 1 &&
                            findedContrat.data["CLD_CONTROLE_RETOUR"] === 1
                        )
                            state = 2;
                        if (
                            findedContrat.data["CLD_CONTROLE_DEPART"] === 0 &&
                            findedContrat.data["CLD_CONTROLE_RETOUR"] === 0
                        )
                            state = 0;
                        if (state !== undefined) {
                            // that.selectedItem[0] = state;
                            findedContrat.data =
                                get_materials_data_to_show(findedContrat);
                            openDialog(findedContrat.data);
                        }
                        isqCodeReaderOpen.value = false;
                    }
                }
                if (selectedMaterial.value && selectedMaterial.value.data) {
                    engine.set_client_state(
                        selectedMaterial.value.data["CLD_LIEN"],
                        get_client_state.value,
                        function() {}
                    );
                }
            } else {
                materials_list.value = []
            }
            isLoading.value = false;
        });
    }

    const newMaterielForm = function(materiel, force) {
        isLoading.value = true;

        if (force == undefined || force == false) {
            // store.commit("setStoredData", materiel)
            if (store.state.storedData == undefined)
                store.state.storedData = []
            store.state.storedData.push(materiel)
            closeDialog()
            isLoading.value = false;
        } else {

            let data = JSON.parse(JSON.stringify(materiel.data));
            let etatdlPdf = JSON.parse(JSON.stringify(materiel.etatdlPdf));

            delete data.CLD_AGENCY;
            delete data.CL_CLIENT_EMAIL;
            delete data.CL_CLIENT_RAISON_SOCIALE;
            delete data.CL_CLIENT_TELEPHONE;
            delete data.CL_NOM_CLIENT;
            delete data.CL_ID_CLIENT;
            delete data.CL_COMPLEMENT_STOCK
            delete data.CLD_ACTION
            delete data.isSelected

            newMaterielData.value = data

            if (data.CLD_DATE_DEPART_VALIDATION == "")
                delete data.CLD_DATE_DEPART_VALIDATION;
            if (data.CLD_DATE_RETOUR_VALIDATION == "")
                delete data.CLD_DATE_RETOUR_VALIDATION;

            if (data.CLD_ERREUR != undefined) {
                if (data.CLD_ERREUR == true) data["CLD_ERREUR"] = 1
                else data["CLD_ERREUR"] = 0
            }

            data["CLD_CHECK_LIST_JSON_GESTION"] = JSON.stringify(
                data["CLD_CHECK_LIST_JSON_GESTION"]
            );
            data["CLD_CHECK_LIST_JSON"] = JSON.stringify(
                data["CLD_CHECK_LIST_JSON"]
            );

            if (data["CLD_EQUIPEMENTS"])
                data["CLD_EQUIPEMENTS"] = JSON.stringify(
                    data["CLD_EQUIPEMENTS"]
                );

            selectedMaterial.value.data["CLD_LIEN"] = data["CLD_LIEN"];

            saveCopyDataAsMail(etatdlPdf.pdfLink, data["CLD_PHOTO_1"], data["CLD_PHOTO_2"], data, function() {
                engine.file_upload(etatdlPdf.pdfLink, etatdlPdf.dropBoxPath, function() {});

                if (data["CLD_CONTROLE_DEPART"] == '0' && data["CLD_CONTROLE_RETOUR"] == '0') {
                    data["CLD_HEURE_DEPART"] = data["CLD_HEURE_DEPART"].replace("H", ":");
                    if (materiel.edit === false) {
                        data["CLD_CONTROLE_DEPART"] = 1;
                        data["CLD_CONTROLE_RETOUR"] = 0;
                    }
                    uploadPhoto("CLD_PHOTO_1", data["CLD_PHOTO_1"], materiel);

                    delete data.CLD_PHOTO_1;
                    delete data.CLD_PHOTO_2;

                    engine.edit_material(
                        data["CLD_MATRICULE"],
                        data["CLD_NUM_CONTRAT"],
                        data["CLD_IDEnreg"],
                        data,
                        function(rep) {
                            if (rep.code == 0) {
                                let index = store.state.storedData.findIndex((val) => val.data.CLD_ID_CONTRAT_LOC_DATA == materiel.data.CLD_ID_CONTRAT_LOC_DATA)
                                if (index != undefined && index >= 0) {
                                    // store.state.storedData = store.state.storedData.splice(index, 1)
                                    // store.commit('deleteEtadlByIndex', index)
                                    if (store.state.storedData == undefined)
                                        store.state.storedData = []
                                    store.state.storedData.splice(index, 1)

                                }
                            }
                            if ((data['CLD_SIGNATURE_DEPART'] == undefined || data['CLD_SIGNATURE_DEPART'].length == 0)) {
                                send_alert_not_signed(data, 'départ')
                            }
                            setMaterialList();
                            // closeDialog();
                        }
                    );
                } else {
                    data["CLD_HEURE_RETOUR"] = data["CLD_HEURE_RETOUR"].replace("H", ":");
                    if (materiel.edit === false) {
                        data["CLD_CONTROLE_DEPART"] = 1;
                        data["CLD_CONTROLE_RETOUR"] = 1;
                    } {
                        uploadPhoto("CLD_PHOTO_1", data["CLD_PHOTO_1"], materiel);
                        uploadPhoto("CLD_PHOTO_2", data["CLD_PHOTO_2"], materiel);
                    }
                    delete data.CLD_PHOTO_1;
                    delete data.CLD_PHOTO_2;

                    engine.edit_material(
                        data["CLD_MATRICULE"],
                        data["CLD_NUM_CONTRAT"],
                        data["CLD_IDEnreg"],
                        data,
                        function(rep) {

                            if (rep.code == 0) {
                                let index = store.state.storedData.findIndex((val) => val.data.CLD_ID_CONTRAT_LOC_DATA == materiel.data.CLD_ID_CONTRAT_LOC_DATA)
                                if (index != undefined && index >= 0) {
                                    // store.commit('deleteEtadlByIndex', index)
                                    if (store.state.storedData == undefined)
                                        store.state.storedData = []
                                    store.state.storedData.splice(index, 1)

                                }
                            }
                            if (data['CLD_SIGNATURE_RETOUR'] == undefined || data['CLD_SIGNATURE_RETOUR'].length == 0)
                                send_alert_not_signed(data, 'retour')
                            if ((data['CLD_OR_ID'] && data['CLD_OR_ID'].length > 0) && (data["CLD_CARA_MATERIEL"] && data["CLD_CARA_MATERIEL"].length > 0)) {
                                engine.creat_or_retour(data['CLD_OR_ID'], function() {})
                            }
                            setMaterialList();
                        }
                    );
                }
            })
        }
    }

    const saveCopyDataAsMail = function(pdfLink, CLD_PHOTO_1, CLD_PHOTO_2, formData, callback) {
        console.log("saveCopyDataAsMail", etadlOptions.value['savedSignature']);
        if (etadlOptions.value['savedSignature'] != undefined) {
            let photo = { "depart": JSON.parse(CLD_PHOTO_1), "retour": JSON.parse(CLD_PHOTO_2) }

            engine.send_pdf_by_email(
                etadlOptions.value['savedSignature'],
                pdfLink,
                formData["CLD_NUM_CONTRAT"] + " / " + formData["CLD_MATRICULE"],
                formData["CLD_AGENCY"],
                photo,
                function() {
                    callback()
                }
            )
        } else {
            callback()
        }
    }

    // const newMaterielForm = function(materiel) {
    //     isLoading.value = true;
    //     let networkLvl = materiel.networkLvl
    //     let data = JSON.parse(JSON.stringify(materiel.data));

    //     if (networkLvl == 0 && materiel.isForced == false) {
    //         isLoading.value = false;
    //         closeDialog()
    //     }
    //      else {
    //         delete data.CLD_AGENCY;
    //         delete data.CL_CLIENT_EMAIL;
    //         delete data.CL_CLIENT_RAISON_SOCIALE;
    //         delete data.CL_CLIENT_TELEPHONE;
    //         delete data.CL_NOM_CLIENT;
    //         delete data.CL_ID_CLIENT;
    //         delete data.CL_COMPLEMENT_STOCK
    //         delete data.CLD_ACTION
    //         delete data.isSelected

    //         newMaterielData.value = data

    //         if (data.CLD_DATE_DEPART_VALIDATION == "")
    //             delete data.CLD_DATE_DEPART_VALIDATION;
    //         if (data.CLD_DATE_RETOUR_VALIDATION == "")
    //             delete data.CLD_DATE_RETOUR_VALIDATION;

    //         if (data.CLD_ERREUR != undefined) {
    //             if (data.CLD_ERREUR == true) data["CLD_ERREUR"] = 1
    //             else data["CLD_ERREUR"] = 0
    //         }

    //         data["CLD_CHECK_LIST_JSON_GESTION"] = JSON.stringify(
    //             data["CLD_CHECK_LIST_JSON_GESTION"]
    //         );
    //         data["CLD_CHECK_LIST_JSON"] = JSON.stringify(
    //             data["CLD_CHECK_LIST_JSON"]
    //         );

    //         if (data["CLD_EQUIPEMENTS"])
    //             data["CLD_EQUIPEMENTS"] = JSON.stringify(
    //                 data["CLD_EQUIPEMENTS"]
    //             );

    //         selectedMaterial.value.data["CLD_LIEN"] = data["CLD_LIEN"];
    //         if (data["CLD_CONTROLE_DEPART"] == '0' && data["CLD_CONTROLE_RETOUR"] == '0') {
    //             data["CLD_HEURE_DEPART"] = data["CLD_HEURE_DEPART"].replace("H", ":");
    //             if (materiel.edit === false) {
    //                 data["CLD_CONTROLE_DEPART"] = 1;
    //                 data["CLD_CONTROLE_RETOUR"] = 0;
    //             }
    //             uploadPhoto("CLD_PHOTO_1", data["CLD_PHOTO_1"], materiel);

    //             delete data.CLD_PHOTO_1;
    //             delete data.CLD_PHOTO_2;

    //             engine.edit_material(
    //                 data["CLD_MATRICULE"],
    //                 data["CLD_NUM_CONTRAT"],
    //                 data["CLD_IDEnreg"],
    //                 data,
    //                 function() {
    //                     if ((data['CLD_SIGNATURE_DEPART'] == undefined || data['CLD_SIGNATURE_DEPART'].length == 0)) {
    //                         send_alert_not_signed(data, 'départ')
    //                     }
    //                     setMaterialList();
    //                     nbrUploadDone.value += 2
    //                         // closeDialog();
    //                 }
    //             );
    //         } else {
    //             data["CLD_HEURE_RETOUR"] = data["CLD_HEURE_RETOUR"].replace("H", ":");
    //             if (materiel.edit === false) {
    //                 data["CLD_CONTROLE_DEPART"] = 1;
    //                 data["CLD_CONTROLE_RETOUR"] = 1;
    //             } {
    //                 uploadPhoto("CLD_PHOTO_1", data["CLD_PHOTO_1"], materiel);
    //                 uploadPhoto("CLD_PHOTO_2", data["CLD_PHOTO_2"], materiel);
    //             }
    //             delete data.CLD_PHOTO_1;
    //             delete data.CLD_PHOTO_2;

    //             engine.edit_material(
    //                 data["CLD_MATRICULE"],
    //                 data["CLD_NUM_CONTRAT"],
    //                 data["CLD_IDEnreg"],
    //                 data,
    //                 function() {
    //                     if (data['CLD_SIGNATURE_RETOUR'] == undefined || data['CLD_SIGNATURE_RETOUR'].length == 0)
    //                         send_alert_not_signed(data, 'retour')
    //                     if ((data['CLD_OR_ID'] && data['CLD_OR_ID'].length > 0) && (data["CLD_CARA_MATERIEL"] && data["CLD_CARA_MATERIEL"].length > 0)) {
    //                         engine.creat_or_retour(data['CLD_OR_ID'], function() {})
    //                     }
    //                     setMaterialList();
    //                     nbrUploadDone.value += 1
    //                         // closeDialog();
    //                 }
    //             );
    //         }
    //     }
    // }

    const send_alert_not_signed = function(dataForm, etadl_type) {
        let alerts_data = {
            client: dataForm['CLD_CLIENT_RAISON_SOCIALE'],
            contrat: dataForm['CLD_NUM_CONTRAT'],
            matricule: dataForm['CLD_MATRICULE'],
            depart: dataForm['CLD_DATE_DEPART'],
            retour: dataForm['CLD_DATE_RETOUR'],
        }
        if (etadlOptions.value['sendAlertNotSigned'])
            engine.send_alert_not_signed(alerts_data, get_user_information.value.user_name, etadl_type, function() {})
    }

    const get_materials_data_to_show = function(material) {
        selectedMaterial.value = material;
        let filtered_material = {};

        if (materials_list.value) {
            filtered_material = material.data
        }
        filtered_material["CLD_AGENCY"] = selectedAgency.value;

        return filtered_material;
    }

    const formatFromServerToTable = function(list) {
        let formatedData = [];
        for (let i = 0; i < list.length; i++) {
            let item = { id: list[i].recordId, data: list[i].fieldData };
            formatedData.push(item);
        }
        return formatedData;
    }

    const closeDialog = function() {
        dialog.value = false;
    }
    const updateCalendarDaysToShow = function() {
        engine.get_day_to_hide(function(data) {
            if (data.code == 0) {
                daysToHide.value = data.data.data[0].fieldData.Value.split(',').map((val) => parseInt(val))
            }
        })
    }

    const openDialog = function(item) {
        isLoading.value = true
        console.log("in");
        if (store.state && store.state.storedData && Object.values(store.state.storedData).find((val) => (val.data.CLD_MATRICULE == item.CLD_MATRICULE && val.data.CLD_NUM_CONTRAT == item.CLD_NUM_CONTRAT && val.data.CLD_ID_CONTRAT_LOC_DATA == item.CLD_ID_CONTRAT_LOC_DATA))) {
            snackbarData.value = {
                color: "red",
                text: "L'état des lieux est en attente de synchronisation et n'est plus modifiable.",
                value: true,
            }
        } else {
            let disabled = getItemType(item.CLD_CONTROLE_DEPART, item.CLD_CONTROLE_RETOUR, item.CLD_WAITING).option.disabled;
            dialogData.value = {
                title: getItemType(item.CLD_CONTROLE_DEPART, item.CLD_CONTROLE_RETOUR, item.CLD_WAITING).option.title,
                data: item,
                disabled,
            };
            newMaterielData.value = {}
            dialog.value = true;
        }
        clickOnDeleteBtn.value = false
        console.log("end");
        isLoading.value = false
    }

    const deleteAllSelectedEtadl = function() {
        for (let i = 0; i < selectedEtadl.value.length; i++) {
            let item = selectedEtadl.value[i]
            let reset = false
            if (i + 1 == selectedEtadl.value.length)
                reset = undefined
            deleteEtadlValidation(item, reset)
        }
    }

    const deleteEtadl = function(item) {
        removeEtadlData.value = item
        clickOnDeleteBtn.value = true
    }

    const deleteEtadlValidation = function(item, reset) {
        engine.edit_material(
            item["CLD_MATRICULE"],
            item["CLD_NUM_CONTRAT"],
            item["CLD_IDEnreg"], {
                CLD_CONTROLE_DEPART: '',
                CLD_CONTROLE_RETOUR: '',
            },
            function() {
                if (reset == undefined) {
                    setMaterialList();
                    removeEtadlData.value = false
                    selectedEtadl.value = []
                }
            }
        );
    }

    watch(get_tab_items, (val, oldVal) => {
        if (val && oldVal.length == 0) {
            technicienListe.value = getTechnicienListe()
        }
    });

    return {
        textColor,
        secondColor,
        selectedTechnicien,
        selectedSubFamilly,
        isLoading,
        isqCodeReaderOpen,
        dialog,
        modalUser,
        search,
        selectedItem,
        selectedMaterial,
        dialogData,
        materials_list,
        getSubFamilly,
        technicienListe,
        dataMenu,
        itemsMenu,
        get_client_state,
        get_tab_items,
        get_tab_header,
        get_selected_material_data,
        get_depart_material,
        get_waiting_material,
        get_return_material,
        get_user_information,
        isAdmin,
        snackbarData,
        // showAgencySelector: etadlOptions.value['showAgencySelector'],
        // nbrMaxLoadedPhoto: etadlOptions.value['nbrMaxLoadedPhoto'],
        // nbrMinLoadedPhoto: etadlOptions.value['nbrMinLoadedPhoto'],
        // drawInObservation: etadlOptions.value['drawInObservation'],
        // multipleSelection: etadlOptions.value['multipleSelection'],
        // showErrorInContrat: etadlOptions.value['showErrorInContrat'],
        // showSignByMail: etadlOptions.value['showSignByMail'],
        etadlOptions,
        agency_list,
        defaultForm,
        defaultFormValues,
        getTableHeight,
        removeEtadlData,
        newMaterielData,
        selectedAgency,
        selectedEtadl,
        parcListe,
        deleteAllSelectedEtadl,
        getItemType,
        formatDate,
        setPhoto,
        uploadPhoto,
        deleteAllPhotos,
        updateTable,
        user,
        newMaterielForm,
        get_materials_data_to_show,
        closeDialog,
        updateCalendarDaysToShow,
        openDialog,
        setMaterialList,
        deleteEtadl,
        deleteEtadlValidation,
        getNbrPhotoToLoad,
    };
}